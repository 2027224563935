<template>
  <div class="offering-bounds-wrapper">
    <div
      class="offer-checkbox-root"
      v-if="selectedConfiguration === 'offering'"
    >
      <div class="offer-checkbox-label">
        OFFERING
      </div>
      <div class="offer-checkbox-items-wrapper">
        <div class="offer-checkbox-item">
          <Checkbox
            :model-value="selectedRuleset.offeringConfiguration.offerPreMatch"
            @update:modelValue="togglePreMatchOffer"
          />
          <span @click="togglePreMatchOffer">Prematch</span>
        </div>
        <div class="offer-checkbox-item">
          <Checkbox
            :model-value="selectedRuleset.offeringConfiguration.offerInPlay"
            @update:modelValue="toggleInPlayOffer"
          />
          <span @click="toggleInPlayOffer">Live</span>
        </div>
      </div>
    </div>
    <div class="periods-wrapper">
      <div
        class="period"
        v-for="(period, index) in periods"
        :key="index"
      >
        <div class="period-label">
          {{ period.shortLabel }}
        </div>
        <div class="period-checkbox">
          <Checkbox
            :model-value="period.isOfferedInPeriod"
            @update:modelValue="togglePeriodCheckbox(period)"
          />
        </div>
      </div>
    </div>
    <div class="period-sections-wrapper">
      <div
        class="period-section"
        v-for="period in periods"
        :key="period.label"
      >
        <div class="period-section-header">
          <Checkbox
            :model-value="period.isOfferedInPeriod"
            @update:modelValue="togglePeriodCheckbox(period)"
          />
          {{ period.label }}
        </div>
        <div
          :class="['bound-content', {'has-border': (!isNil(period.overMainLine) || !isNil(period.underMainLine))}]"
          v-if="period.hasTimeValues"
        >
          <div
            class="bound"
            v-if="!isNil(period.fromSecondInPeriod)"
          >
            <div class="bound-header">
              {{ syncToggled ? 'START OFFERING & DISPLAYING' : (selectedConfiguration === 'display' ? 'START DISPLAYING' : 'START OFFERING') }}
            </div>
            <div class="bound-input-row">
              <div class="bound-input-wrapper">
                <input
                  type="text"
                  class="bound-input"
                  :value="calculateMinutes(period.fromSecondInPeriod)"
                  @input="updateStartStopBounds($event.target.value, period, 'start')"
                >
                <span>minutes</span>
              </div>
              from the start time
            </div>
          </div>
          <div
            class="bound-separator"
            v-if="!isNil(period.fromSecondInPeriod)"
          />
          <div
            class="bound"
            v-if="!isNil(period.secondsLeftInPeriod)"
          >
            <div class="bound-header">
              {{ syncToggled ? 'STOP OFFERING & DISPLAYING' : (selectedConfiguration === 'display' ? 'STOP DISPLAYING' : 'STOP OFFERING') }}
            </div>
            <div class="bound-input-row">
              <div class="bound-input-wrapper">
                <input
                  type="text"
                  class="bound-input"
                  :value="calculateMinutes(period.secondsLeftInPeriod)"
                  @input="updateStartStopBounds($event.target.value, period, 'stop')"
                >
                <span>minutes</span>
              </div>
              before the end time
            </div>
          </div>
        </div>
        <div
          class="bound-lines-configuration"
          v-if="!isNil(period.overMainLine) || !isNil(period.underMainLine)"
        >
          <span v-if="syncToggled">Offer & display</span>
          <span v-else>{{ selectedConfiguration === 'offering' ? 'Offer' : 'Display' }}</span>
          <input
            type="text"
            class="market-configuration-input"
            :value="period.overMainLine"
            @input="updateBoundLines($event.target.value, period, 'over')"
          >
          lines over and
          <input
            type="text"
            class="market-configuration-input"
            :value="period.underMainLine"
            @input="updateBoundLines($event.target.value, period, 'under')"
          >
          lines under the mainline
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import {
  isNil, map, orderBy, filter,
} from 'lodash';
import Checkbox from '@/components/common/Checkbox';
import { periodLabelsMappings } from './market-template-helper';

export default {
  components: {
    Checkbox,
  },
  props: {
    syncToggled: {
      type: Boolean,
      default: false,
    },
    selectedConfiguration: {
      type: String,
      default: 'offering',
    },
    selectedRuleset: {
      type: Object,
      required: true,
    },
  },
  emits: {
    toggleOffer: {
      type: String,
    },
    togglePeriodOffering: {
      type: Object,
    },
    updateBoundsTime: {
      type: Object,
    },
    updateBoundLinesValues: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const periods = computed(() => {
      const mappedPeriods = map(props.selectedRuleset[`${props.selectedConfiguration}Configuration`].offeringBounds, (bound, key) => {
        const period = periodLabelsMappings[key];
        const sportLabel = props.selectedRuleset?.template?.sport;
        let label = period?.label;
        let shortLabel = period?.shortLabel;
        const position = period?.position || 999;
        if (sportLabel === 'ICE_HOCKEY' && period) {
          label = label.replace('quarter', 'period');
          shortLabel = shortLabel.replace('Q', 'P');
        }
        if (props.selectedRuleset?.sportLogicTemplate?.name === 'NCAA' && period) {
          label = label.replace('quarter', 'half');
          shortLabel = shortLabel.replace('Q', 'HT');
        }
        // add NCAA exception for Q to Half
        return {
          ...bound,
          label,
          shortLabel,
          position,
          hasTimeValues: !!period?.hasTimeValues,
          bound: key,
        };
      });
      return orderBy(filter(mappedPeriods, (p) => p.label), 'position');
    });

    const updateStartStopBounds = (minutes, period, periodTime) => {
      if (isNil(minutes) || !/^\d+([.,]?\d+)?$/.test(minutes)) return;
      emit('updateBoundsTime', { minutes, period: period.bound, periodTime });
    };

    const updateBoundLines = (line, period, lineType) => {
      if (isNil(line) || !/^\d+([.,]?\d+)?$/.test(line)) return;
      emit('updateBoundLinesValues', { line, period: period.bound, lineType });
    };

    const toggleInPlayOffer = () => {
      emit('toggleOffer', 'inPlay');
    };

    const togglePreMatchOffer = () => {
      emit('toggleOffer', 'prematch');
    };

    const togglePeriodCheckbox = (period) => {
      emit('togglePeriodOffering', period);
    };

    const calculateMinutes = (seconds) => {
      if (isNil(seconds)) return '';
      const intMinutes = parseInt(seconds / 60, 10);
      const decimalMinutes = (seconds % 60) / 60;
      return intMinutes + decimalMinutes;
    };

    return {
      updateStartStopBounds,
      toggleInPlayOffer,
      togglePreMatchOffer,
      periods,
      togglePeriodCheckbox,
      isNil,
      calculateMinutes,
      updateBoundLines,
    };
  },
};
</script>

<style lang="scss">

.market-configurations-popup {
  .offering-bounds-wrapper {
    width: 100%;

    .bound-content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;

      &.has-border {
        padding-bottom: 16px;
        border-bottom: 1px solid #F0F0F0;
      }

      .bound {
        align-items: center;
        color:#6D6D6D;
        font-weight: 400;
        gap: 8px;
        width: 47%;

        .bound-header {
          font-weight: 600;
          font-size: 10px;
          color: #6D6D6D;
          margin-bottom: 2px;
        }

        .bound-input-row {
          height: 32px;
          display: flex;
          align-items: center;
          gap: 8px;
          color: #191414;

           .bound-input-wrapper {
              height: 32px;
              width: 116px;
              padding: 0 8px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-radius: 4px;
              border: 1px solid #F0F0F0;
              box-sizing: border-box;

              .bound-input {
                height: 100%;
                width: 32px;
                color: #191414;
                outline: none;
                display: flex;
                align-items: center;
                border: 0;
                box-sizing: border-box;
              }
              span {
                color: #CDCDCD;
              }
           }
        }

        &.fixed-bound {
          background: #FAFAFA;
          border: 1px solid #F0F0F0;
          box-sizing: border-box;
          border-radius: 4px;
          color: #A9A9A9;
          display: flex;
          align-items: center;
          padding: 0 8px;
        }
      }

      .bound-separator {
        height: 49px;
        width: 1px;
        background: #F0F0F0;
      }
    }

    .bound-lines-configuration {
      margin-top: 16px;
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
    }

    .offer-checkbox-root {
      gap: 8px;
      margin-bottom: 32px;

      .offer-checkbox-label {
        font-weight: 600;
        font-size: 10px;
        color: #6D6D6D;
      }

      .offer-checkbox-items-wrapper {
        display: flex;
        gap: 16px;
        align-items: center;

        .offer-checkbox-item {
          display: flex;
          align-items: center;
          gap: 4px;
          span {
            cursor: pointer;
          }

          .checkbox {
            .checkbox-icon {
              svg {
                path {
                  stroke-width: 1;
                }
              }
            }
          }
        }
      }
    }

    .periods-wrapper {
      width: 100%;
      display: flex;
      margin-bottom: 32px;

      .period {
        height: 64px;
        flex-grow: 1;
        min-width: 0;
        .period-label {
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-size: 14px;
          color: #6D6D6D;
          background-color: #FAFAFA;
        }
        .period-checkbox {
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;

          .checkbox {
            .checkbox-icon {
              svg {
                path {
                  stroke-width: 1;
                }
              }
            }
          }
        }
      }
    }

    .period-sections-wrapper {
      width: 100%;
      .period-section {
        width: 100%;
        margin-bottom: 32px;
        .period-section-header {
          width: 100%;
          height: 32px;
          padding: 0 8px;
          gap: 4px;
          display: flex;
          align-items: center;
          background-color: #FAFAFA;

          .checkbox {
            .checkbox-icon {
              svg {
                path {
                  stroke-width: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
